module.exports = {
  'A3': 'A3',
  'A4': 'A4',
  'A5': 'A5',
  'All Sites': 'Todos os sites',
  'Assisting Verifiers': 'Assistente de de verificadores',
  'COVID-19 (Coronavirus)': 'COVID-19 (Coronavirus)',
  'Cannot load GeoAreas': 'Não é possível carregar GeoAreas',
  'Cannot retrieve Critical Risks': 'Não é possível recuperar Riscos Críticos',
  'Cannot retrieve Go To list': 'Não é possível recuperar Vá Para Lista ',
  'Cannot retrieve company list.': 'Não é possível recuperar a lista de empresas',
  'Cannot retrieve map overlays': 'Não é possível recuperar a sobreposições de mapas',
  'Chinese, Simplified': 'Chinês, Simplificado',
  'Chinese, Simplified (ç®€åŒ–å­—)': 'Chinês, Simplificado (ç®€åŒ–å­—)',
  'Coach': 'Coach',
  'Company Structure': 'Estrutura da Empresa',
  'Compliant Verification': 'Verificação em conformidade',
  'Compliant Verification Group': 'Grupo de verificação em conformidade',
  'Confined Spaces': 'Espaços Confinados',
  'Contact with Electricity': 'Contato com Eletricidade',
  'Contact with Molten Material': 'Contato com metal líquido',
  'Coordinates': 'Coordenadas',
  'Critical Control': 'Controle Crítico',
  'Critical Control Status': 'Status de Controle Crítico',
  'Critical Risk': 'Risco Crítico',
  'Date Range': 'Intervado de datas',
  'Drowning': 'Afogamento',
  'Dutch (Nederlands)': 'Holandês (Holanda)',
  'English (English)': 'Inglês (Inglaterra)',
  'Entanglement and Crushing': 'Aprisionamento e Esmagamento',
  'Entanglement in Rotating Equipment': 'Aprisionamento em equipamento rotativo',
  'Error': 'Erro',
  'Event in a Confined Space': 'Evento em Espaço Confinado',
  'Explosives Handling': 'Manuseio de explosivos',
  'Exposure to Chemicals that Burn': 'Exposição a produtos químicos que queimam',
  'Exposure to Hazardous Substances': 'Exposição a substâncias perigosas',
  'Exposure to Hazardous Substances (Chronic)': 'Exposição a substâncias perigosas (crônica)',
  'Exposure to Thermal Extremes': 'Exposição a temperaturas extremas',
  'Fall from Height': 'Queda de altura',
  'Fall of Ground - Surface': 'Queda de solo - Superfície',
  'Fall of Ground - Underground': 'Queda de solo - Subterrâneo',
  'Falling Objects': 'Queda de materiais',
  'Filter by Company Structure': 'Filtrar por estrutura da empresa',
  'Filter by Critical Risk': 'Filtrar por Risco Crítico',
  'Filter by Date': 'Filtrar por data',
  'Filter by GeoArea': 'Filtrar por GeoArea',
  'Filter by Site': 'Filtrar por site',
  'Filter by Site Level': 'Filtrar por nível do site',
  'Filter by Verification Type': 'Filtrar por tipo de verificação',
  'French (Français)': 'Francês (França)',
  'GeoArea': 'GeoArea',
  'German (Deutsch)': 'Alemão (Alemanha)',
  'Go': 'Vai',
  'Goto': 'Vai para',
  'Goto Coordinates': 'Ir para coordenadas',
  'Goto GeoArea': 'Ir para GeoArea',
  'Grizzly Bear Interactions': 'Interações do Urso Pardo',
  'Hazardous Material': 'Material perigoso',
  'Heavy Vehicle Event - Rollover, Over Edge': 'Evento com veículo pesado - Capotamento, Acima da Borda',
  'Icelandic (Íslensku)': 'Islandês (Islândia)',
  'Incident Date': 'Data do incidente',
  'Incident Details': 'Detalhes do incidente',
  'Incident During Tire Handling': 'Incidente durante o manuseio de pneus',
  'Italian (Italiano)': 'Italiano (Italia)',
  'Landscape': 'Paisagem',
  'Language': 'Idiomas',
  'Legend': 'Legenda',
  'Level': 'Nível',
  'Lifting Operations': 'Operações de içamento',
  'Loading Map Data': 'Carregando dados do mapa',
  'Location': 'Localização',
  'Logout': 'Sair',
  'Malaria': 'Malária',
  'Manager Verification': 'Verificação do gerente',
  'Message': 'Mensagem',
  'Mongolian (Монгол хэл)': 'Mogol (Mongólia)',
  'Mooring Operations': 'Operações de amarração',
  'No incident results found. Please try changing the date range.': 'Nenhum resultado de incidente encontrado. Por favor, tente alterar o intervalo de datas.',
  'No verification results found. Please changing the date range.': 'Nenhum resultado de verificação encontrado. Por favor, altere o intervalo de datas.',
  'Non Compliant Verification': 'Verificação não conforme',
  'Non Compliant Verification Group': 'Grupo de verificação não conforme',
  'Norwegian (Norsk)': 'Norueguês (Noruega)',
  'Operator Task Based Verification': 'Verificação baseada na tarefa do operador',
  'Operator Verification': 'Verificação do Operador',
  'Orientation': 'Orientação',
  'Paper Size': 'Tamanho do papel',
  'Physical Location': 'Localização física',
  'Please note: Changing these options prepares the map for printing.': 'Por favor, note: Alterar estas opções prepara o mapa para impressão',
  'Portrait': 'Retrato',
  'Portuguese (PortuguÃªs)': 'Português (Brasil)',
  'Potential Fatality Incident - Energy': 'Incidente com Potencial de Fatalidade - Energia',
  'Potential Fatality Incident - Zero Energy': 'Incidente com Potencial de Fatalidade - Energia Zero',
  'Print': 'Imprimir',
  'Print Data': 'Imprimir dados',
  'Print Map': 'Imprimir mapa',
  'Print Options': 'Opções de impressão',
  'Print Preview': 'Visualização da impressão',
  'Rail Collision': 'Colisão ferroviária',
  'Rail Impact on Person': 'Atropelamento ferroviário',
  'Repeated Potential Fatality Incident - Energy': 'Recorrência de Incidente com Potencial de Fatalidade - Energia',
  'Repeated Potential Fatality Incident - Zero Energy': 'Recorrência Incidente com Potencial de Fatalidade - Energia Zero',
  'Russian (русский)': 'Russo (Rússia)',
  'Select / Deselect All': 'Selecionar / Desmarcar tudo',
  'Select a Company Structure': 'Selecione uma estrutura da empresa',
  'Select a GeoArea': 'Selecione uma GeoArea',
  'Select a Site': 'Selecione um site',
  'Serbian (Српски)': 'Sérvio (Sérvia)',
  'Site': 'Site',
  'Slope Failure': 'Falha no talude',
  'Sorry, we couldn\'t load incidents. Please try reloading the page.': 'Desculpe, não foi possível carregar os incidentes. Por favor, tente recarregar a página.',
  'Sorry, we couldn\'t load verifications. Please try reloading the page.': 'Desculpe, não foi possível carregar verificações. Por favor, tente recarregar a página.',
  'Spanish (Español)': 'Espanhol (Espanha)',
  'Struck by Falling Object': 'Atingido por queda de material',
  'Supervisor Task Based Verification': 'Verificação baseada na tarefa do supervisor',
  'Supervisor Verification': 'Verificação de Supervisor',
  'Surface': 'Superfície',
  'US Legal': 'Legislação dos EUA',
  'US Letter': 'Carta dos EUA',
  'Unauthorised': 'Não autorizado',
  'Uncontrolled Load During Lifting': 'Carga descontrolada durante o içamento',
  'Uncontrolled Release of Energy': 'Liberação de energia não controlada',
  'Underground Fire': 'Incêndio subterrâneo',
  'Underground Fire or Explosion': 'Incêndio ou explosão subterrânea',
  'Underground Hazardous Atmosphere': 'Atmosfera perigosa subterrânea',
  'Underground Hoisting': 'Elevadores subterrâneos',
  'Underground Inrush': 'Inruh subterrâneo',
  'Underground Rock Fall': 'Queda de rocha subterrâneo',
  'Unplanned Initiation of Explosives': 'Ignição não planejada de explosivos',
  'Vehicle Collision (Heavy and Light)': 'Colisão de veículos (Pesado e Leve)',
  'Vehicle Collision or Rollover': 'Colisão e capotamento de veículos',
  'Vehicle Impact on Person': 'Atropelamento por veículo',
  'Vehicle Incident': 'Incidente com veículo',
  'Vehicle Pedestrian Interaction - Surface': 'Interação de veículos e pedestres - Superfície',
  'Vehicle Pedestrian Interaction - Underground': 'Interação de veículos e pedestres - Subterrâneo',
  'Verification Date': 'Data da verificação',
  'Verification Details': 'Detalhes da verificação',
  'Verification Type': 'Tipo de verificação',
  'Verifier': 'Verificador',
  'View Incident': 'Visualizar incidente',
  'View Verification': 'Ver verificação',
  'Warning': 'Aviso',
  'Finnish (Suomalainen)': 'Finlandês (Finlândia)',
  'You do not have access to this application. Please contact your site administrator.': 'Você não tem acesso a este aplicativo. Por favor, entre em contato com o administrador do Site',
  'You will have to repeat these settings once you hit \'Print\' below': 'Você terá que repetir estas configurações uma vez que você pressione \'imprimir\' abaixo',
  'Indonesian (Bahasa Indonesia)': 'Indonésia (Indonésio)'
};
